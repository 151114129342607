<template>
  <div id="clockdate">
    <div class="clockdate-wrapper">
      <div id="clock"></div>
      <div id="date"></div>
    </div>
    <span class="d-none"> {{currentDate}}</span>
  </div>
</template>

<script>
export default {
  name: 'clock',
  data() {
    return {
      currentDate: null,
      currentTime: null,
    }
  },
  watch: {
    currentDate(newValue) {
      this.$emit('currentDate', newValue)
    },
    currentTime(newValue) {
      this.$emit('currentTime', newValue)
    },
  },
  methods: {
    Time() {
      const me = this
      const today = new Date()
      // Creación de hora
      const hr = today.getHours()
      let min = today.getMinutes()
      let sec = today.getSeconds()
      // Add a zero in front of numbers<10
      min = this.checkTime(min)
      sec = this.checkTime(sec)
      // Add a zero in front of numbers<10
      document.getElementById('clock').innerHTML = hr + ' : ' + min + ' : ' + sec
      this.currentTime = hr + ' : ' + min + ' : ' + sec
      // Creación de Fecha
      const months = ['Enero', 'Fecbrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      const days = ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vier', 'Sab']
      const curWeekDay = days[today.getDay()]
      const curDay = today.getDate()
      const curMonth = months[today.getMonth()]
      const curYear = today.getFullYear()
      const date = curWeekDay + ', ' + curDay + ' ' + curMonth + ' ' + curYear
      document.getElementById('date').innerHTML = date
      this.currentDate = date
      setTimeout(function() { me.Time() }, 500)
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
  },
  mounted() {
    this.Time()
  },
}
</script>

<style scoped>
.clockdate-wrapper {
  background-color: #333;
  padding:3px;
  /* max-width:350px; */
  width:55%;
  text-align:center;
  border-radius:10px;
  margin:0 auto;
}
#clock{
  background-color:#333;
  font-family: sans-serif;
  font-size:30px;
  text-shadow:0px 0px 1px #fff;
  color:#fff;
}
#clock span {
  color:#888;
  text-shadow:0px 0px 1px #333;
  font-size:20px;
  position:relative;
  /* top:-27px;
  left:-10px; */
}
#date {
  letter-spacing:2px;
  font-size:10px;
  font-family:arial,sans-serif;
  color:#fff;
}
</style>
