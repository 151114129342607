<template>
<div>  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5>Control de Asistencia</h5> </div>
  <my-breadcrumbs :routes="routes"/>
  <!--TITULOS  -->

  <clock></clock>

  <b-container fluid>
    <div class="row justify-content-center pt-5">
      <b-card class="overflow-hidden p-5" style="max-width: 860px;">
        <b-row no-gutters>
          <b-col sm="4" offset-sm="4" md="4" offset-md="0">
            <b-card-img src="/resources/images/content/cards/login.png"
                             alt="Acceso" class="rounded-0"/>
          </b-col>
          <b-col sm="8" offset-sm="2" md="8" offset-md="0">
            <b-card-body class="text-center">
              <b-card-title class="mb-5">
                <span v-if="type ==='checkIn'">Entrada</span>
                <span v-if="type ==='checkOut'">Salida</span>
              </b-card-title>
              <!--Entrada o Salida-->
                <b-form-group class="mb-0" v-if="!type">
                  <b-input-group>
                    <b-form-select v-model="type" id="type" :options="Types" label="Tipo de Registro">
                      <template slot="first">
                        <b-form-select-option value="" >Tipo de Registro</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              <!--Entrada o Salida-->
              <!--FORMULARIO-->
              <b-container fluid>
                <a-form-model class="mb-4" :model="form" :rules="formRules" ref="acessForm" v-if="type">
                  <a-steps :current="current">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                  </a-steps>
                  <div class="steps-content">
                    <template v-if="steps[current].title === 'Clave'">
                      <a-form-model-item class="my-4" ref="code" prop="code" extra="Presione enter para continuar">
                        <a-input v-model="form.code" placeholder="Clave"
                                 size="large" class="text-center"
                                 @pressEnter="handleSearchShift()"
                        />
                      </a-form-model-item>
                    </template>
                    <template v-else-if="steps[current].title  === 'Turno'">
                      <!--SELECT -Turno -->
                      <a-form-model-item class="my-4" label="Turno" ref="operationShifts" prop="operationShifts" >
                        <a-select v-model="form.operationShifts"
                                  placeholder="Seleccionar Turno"
                                  show-search :filter-option="filterOption">
                          <a-select-option  v-for="option in operationShifts" :key="option.id" :value="option.id" >
                            {{ option.name }}
                            ({{ moment(option.initial_time).format('HH:mm') }} -
                            {{ moment(option.final_time).format('HH:mm') }} )
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                      <!--SELECT -Turno -->
                      <!--Botones -->
                      <b-button variant="primary" pill @click="Submit">Registrar</b-button>
                      <!--Botones -->
                    </template>
                  </div>
                  <div class="steps-action mt-5">
                    <b-button  variant="primary" v-if="current < steps.length - 1" @click="handleSearchShift" :disabled="this.form.code == ''">
                      Siguiente
                    </b-button>
                    <b-button variant="light" v-if="current > 0" style="margin-left: 8px" @click="current--">
                      Anterior
                    </b-button>
                    <b-button variant="secondary" @click="Reset">Cancelar</b-button>
                  </div>
                  <a-steps :current="current">
                  </a-steps>
                </a-form-model>
              </b-container>
              <!--FORMULARIO-->
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-container>
<!--  -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import clock from '@/components/clock'
export default {
  name: 'assistsControl',
  mixins: [fractalMixin],
  components: {
    clock,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control de Operaciones',
          name: 'operationControl',
        },
        {
          breadcrumbName: 'Control de Personal',
          name: 'personalControl',
        },
        {
          breadcrumbName: 'Control de Asistencia',
          aIcon: '',
        },
      ],
      // form: this.$form.createForm(this),
      form: {
        code: '',
        operationShifts: undefined,
      },
      formRules: {
        code: [{ required: true, message: 'Debe ingerar su clave de empleado.', trigger: 'blur' }],
        operationShifts: [{ required: true, message: 'Debe especificar el turno en cuestión.', trigger: 'change' }],
      },
      type: '',
      Types: [
        { value: 'checkIn', text: 'Entrada' },
        { value: 'checkOut', text: 'Salida' },
      ],
      operationShifts: [],
      current: 0,
      steps: [
        { title: 'Clave' },
        { title: 'Turno' },
      ],
    }
  },
  methods: {
    Reset() {
      this.type = ''
      this.current = 0
      this.form = {
        code: '',
        operationShifts: undefined,
      }
    },
    async handleSearchShift () {
      if (this.form.code) {
        this.current = 1
        this.operationShifts = await this.GetResource('/operationShifts', {
          sort: 'name',
          'filter[operationEmployees]': this.form.code,
        })
      }
    },
    Submit() {
      this.$refs.acessForm.validate(async(valid) => {
        if (valid) {
          const auxThis = this
          const requestData = {
            code: this.form.code,
            operationShifts: this.form.operationShifts,
            type: this.type,
            dateTime: moment(),
          }
          const confirm = Modal.confirm({
            title: '¿Esta seguro hacer su registro con Fecha y hora de:',
            content: `${requestData.dateTime.format('DD/MM/YYY - HH:mm')}?`,
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            zIndex: 3000,
            async onOk () {
              confirm.destroy()
              await auxThis.Send(requestData)
            },
          })
        } else return false
      })
    },
    Success() {
      this.$success({
        title: 'Registro exitoso',
        content: (
          <div>
            <p>Se ha realizado el registro de Entrada/Salida de forma exitosa</p>
          </div>
        ),
      })
    },
    async Send(data) {
      data.dateTime = `${data.dateTime.format()}`
      await this.AxiosPost('/register_assist', data, () => {
        this.Reset()
        this.Success()
      }, () => {
        this.Reset()
      })
    },
  },
}
</script>

<style lang="scss" module>
</style>
